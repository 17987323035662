import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import onlineLogo from "../../images/MobileBusiness/online.png";
import CityGreen from "../../images/MobileBusiness/city1.svg";
import CityOrange from "../../images/MobileBusiness/city2.svg";
import CityBlue from "../../images/MobileBusiness/city3.svg";
import CityPurple from "../../images/MobileBusiness/city4.svg";
import "./styles.scss";

const MobileBusiness = (props) => {
  
  const { t } = useTranslation();
  const { forWho, handleOpen } = props;
  return (
    <>
      <Row className="mobile-busines">
        <Col className="mobile-busines-content-wrapper" lg={8} xl={8}>
          <div className={forWho === "info" ? "mobile-busines-content info" : "mobile-busines-content"}>
            <h2 className={forWho === "info" ? "mobile-busines-title info" : "mobile-busines-title"} >{t("MobileBusiness.1")}</h2>
            <p className={forWho === "info" ? "mobile-busines-info info" : "mobile-busines-info"}>
              <span>Utilitium</span>
              {forWho !== "info"
                ?

                t("MobileBusiness.2")

                :
                t("Info.25")

              }
            </p>
          </div>
        </Col>
        <Col lg={4} xl={4} className="mobile-busines-image-wrapper">
          <div className="mobile-image-title">
            <span className="image-title-first">{t("MobileBusiness.8")}</span>
            <span className="image-title-second">{t("MobileBusiness.7")}</span>
          </div>
          <Image className="mobile-busines-image" src={onlineLogo} />
        </Col>
      </Row>
      <Row className="mobile-buildings-wrapper">
        <Col className="mobile-buildings" lg={3}>
          <Image src={CityGreen} />
          <div className="mobile-buildings-title blue">
            {t("MobileBusiness.3")}
          </div>
        </Col>
        <Col className="mobile-buildings" lg={3}>
          <Image src={CityOrange} />
          <div className="mobile-buildings-title orange">
            {t("MobileBusiness.4")}
          </div>
        </Col>
        <Col className="mobile-buildings" lg={3}>
          <Image src={CityBlue} />
          <div className="mobile-buildings-title green">
            {t("MobileBusiness.5")}
          </div>
        </Col>
        <Col className="mobile-buildings" lg={3}>
          <Image src={CityPurple} />
          <div className="mobile-buildings-title purple">
            {t("MobileBusiness.6")}
          </div>
        </Col>
        {
          forWho
            ?
            <Row className="d-flex justify-content-center w-100">
              <div className="mybtnforinfo" onClick={() => handleOpen("Info.26", "utilitiumPresentation")}>
                <span>
                  {t(`Info.27`)}
                </span>
              </div>
            </Row>
            :
            <div></div>
        }
      </Row>
      <Row className={forWho === "info" ? "mobile-buildings-tablet-row info" : "mobile-buildings-tablet-row"}>
        <Col md={forWho === "info" ? 6 : 4} xs={forWho === "info" ? 6 : 4} className={forWho === "info" ? "Blockinfoleft" : ""}>
          <div className="mobile-buildings-tablet-wrapper">
            <div className="mobile-buildings-tablet">
              <Image src={CityGreen} />
              <div className="mobile-buildings-title blue">
                {t("MobileBusiness.3")}
              </div>
            </div>
            <div className="mobile-buildings-tablet">
              <Image src={CityBlue} />
              <div className="mobile-buildings-title green">
                {t("MobileBusiness.5")}
              </div>
            </div>
          </div>
        </Col>
        {
          forWho === "info"
            ?
            <div></div>
            :
            <Col md={4} xs={4} className="mobile-buildings-image-col">
              <div className="mobile-buildings-tablet-image">
                <div className="mobile-image-title">
                  <span className="image-title-first">{t("MobileBusiness.8")}</span>
                  <span className="image-title-second">
                    {t("MobileBusiness.7")}
                  </span>
                </div>
                <Image className="mobile-busines-image" src={onlineLogo} />
              </div>
            </Col>
        }

        <Col className={forWho === "info" ? "Blockinforight" : ""} md={forWho === "info" ? 6 : 4} xs={forWho === "info" ? 6 : 4}>
          <div className="mobile-buildings-tablet-wrapper">
            <div className="mobile-buildings-tablet">
              <Image src={CityOrange} />
              <div className="mobile-buildings-title orange">
                {t("MobileBusiness.4")}
              </div>
            </div>
            <div className="mobile-buildings-tablet">
              <Image src={CityPurple} />
              <div className="mobile-buildings-title purple">
                {t("MobileBusiness.6")}
              </div>
            </div>
          </div>
        </Col>
        {
          forWho === "info"
            ?
            <Row className="d-flex justify-content-center w-100">
              <div className="mybtnforinfo" onClick={() => handleOpen("Info.26", "utilitiumPresentation")}>
                <span>
                  {t(`Info.27`)}
                </span>
              </div>
            </Row>
            :
            <div></div>
        }
      </Row>
      <Row className={forWho === "info" ? "mobile-buildings-mobile-row info" : "mobile-buildings-mobile-row"}>
        <Col className={forWho === "info" ? "newline" : ""} md={4} xs={4}>
          <div className="mobile-buildings-mobile-wrapper">
            <div className="mobile-buildings-mobile left left-top">
              <Image src={CityGreen} />
              <div className="mobile-buildings-title blue">
                {t("MobileBusiness.3")}
              </div>
            </div>
            <div className="mobile-buildings-mobile right right-top">
              <Image src={CityOrange} />
              <div className="mobile-buildings-title orange">
                {t("MobileBusiness.4")}
              </div>
            </div>
          </div>
        </Col>
        {

          forWho === "info"
            ?
            <Col md={4} xs={4} ></Col>
            :
            <Col className="mobile-buildings-image-col">
              <div className="mobile-buildings-mobile-image">
                <div className="mobile-image-title">
                  <span className="image-title-first">{t("MobileBusiness.8")}</span>
                  <span className="image-title-second">
                    {t("MobileBusiness.7")}
                  </span>
                </div>
                <Image className="mobile-busines-image" src={onlineLogo} />
              </div>
            </Col>
        }
        <Col md={4} xs={4} className={forWho === "info" ? "newline2" : ""}>
          <div className="mobile-buildings-mobile-wrapper bottom-wrapper">
            <div className="mobile-buildings-mobile left left-bottom">
              <Image src={CityBlue} />
              <div className="mobile-buildings-title green">
                {t("MobileBusiness.5")}
              </div>
            </div>
            <div className="mobile-buildings-mobile right right-bottom">
              <Image src={CityPurple} />
              <div className="mobile-buildings-title purple">
                {t("MobileBusiness.6")}
              </div>
            </div>

          </div>
        </Col>
        {
          forWho === "info"
            ?
            <Row className="d-flex justify-content-center w-100">
              <div className="mybtnforinfo" onClick={() => handleOpen("Info.26", "utilitiumPresentation")}>
                <span>
                  {t(`Info.27`)}
                </span>
              </div>
            </Row>
            :
            <div></div>
        }
      </Row>


    </>
  );
};

export default MobileBusiness;
